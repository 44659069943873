import React, { useEffect } from 'react'
import loadable from '@loadable/component'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import ReactDOM from 'react-dom'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { DatabaseRef } from '../firebase';
import { kebabCase } from 'lodash'
import Img from "gatsby-image"

const BlogRoll3 = loadable(() => import('../components/BlogRoll3'));
const ReactStars = loadable(() => import('react-rating-stars-component'));
const ToC = loadable(() => import('../components/ToC'));
const CommentsSection = loadable(() => import('../components/Comments/CommentsSection'));


export const BlogPostTemplate = ({
  id,
  content,
  date,
  contentComponent,
  description,
  tags,
  title,
  author,
  time,
  sentences,
  paragraphs,
  words,
  slug,
  headings,
  helmet,
  location,
  featuredimage
}) =>

{


  function matchAuthor() {
    let bio;
    let avatar;

    if (author === 'Daria'){
      bio = 'You can write to her in any language ... as long as Google Translate has it in its database ;) In the breaks between maintaining, writing, catching likes and researching, she hunts for tickets to the theater and concerts.';
      avatar = '/icons/avatars/daria.jpg';
    } else if(author === 'Agnieszka'){
      bio = 'Experienced manager, e-PR specialist and founder of the e-multicontent multilingual copywriting agency. Since 2012, she has been actively supporting e-commerce businesses, helping them to appear or strengthen their position on the web, through the highest quality content, including effective product descriptions. In her work, she focuses on dialogue, good relations and mutual complementation of team members. She considers the combination of copywriting skills, language competences and knowledge of the target market with SEO techniques to be the greatest asset of her company.';
      avatar = '/icons/avatars/aga.jpg';
    } else if(author === 'Marta'){
      bio = 'Content marketing specialist and copywriter with many years of experience in creating marketing and sales texts. She has been associated with e-commerce since 2011, acting for one of the leading price comparison websites, a website with opinions on online stores and an international media group. At e-multicontent, she deals with the creation of content marketing strategies for companies, customer relations and the preparation of high-quality content for online stores.';
      avatar = '/icons/avatars/marta.jpg';
    } else if (author === 'Joanna') {
      bio = 'At e-multicontent, she not only deals with content creation, but also coordinates the work of translators and native speakers, ensuring that all texts are delivered on time. As a Ukrainian philologist and copywriter, she combines theoretical knowledge with practice, not only in the field of texts, but also translations. She uses her photographic experience to care for the visual layer of content. She is eager to learn new things and use the experience of others.';
      avatar = '/icons/avatars/joanna.jpg';
    } else if( author === 'Guest post by our business partner'){
      bio = '';
      avatar = '/icons/icon-144x144.png';
    } else if( author === 'e-multicontent editorial team'){
      bio = '';
      avatar = '/icons/icon-144x144.png';
    } else if( author === 'Alicja'){
      bio = "W e-multicontent zajmuje się content marketingiem, a także działaniami PR oraz media relations. Stawia na pogłębianie relacji i długofalową współpracę zarówno z klientami, jak i dziennikarzami. Swoje doświadczenie zdobywała pracując m.in. w redakcjach mediów drukowanych i internetowych, dzięki czemu wie, że rzetelny research to podstawa, a dobry tekst musi wywoływać emocje. Choć nie lubi pracy pod presją czasu to zawsze pilnuje deadlin'ów.";
      avatar = '/icons/avatars/alicja.jpg';
    }

  if (document.getElementById('bio') !== null){
    document.getElementById('bio').innerText = bio;
    document.getElementById('author-avatar').src = avatar;

    if(author === 'Guest post by our business partner'){
      document.getElementById('author-avatar').style.backgroundColor = '#36b3d2';
    }
  }
  }




  let creativeJSONLD =
  {
        "@context": "https://schema.org/",
        "@type": "CreativeWorkSeries",
        name: title,
        "aggregateRating": {
          "@type": "AggregateRating",
          ratingValue: "0",
          "bestRating": "5",
          ratingCount: "0"
        }
      }



  let BrewstrRef = DatabaseRef.child('ratings/'+id);

  function readRatings(){
    var element;
    BrewstrRef.once("value").then(function(snapshot){
        var loginInfo=snapshot.val();

        if((loginInfo !== null)&&(loginInfo !== undefined)){
          const entries = Object.entries(loginInfo);

        if(document.getElementById('numof') !== null){
          document.getElementById('numof').innerText = 'Number of ratings: ' + entries.length;
        }
          creativeJSONLD.aggregateRating.ratingCount = String(entries.length);

          let rats = [];

          for (let i=0;i<entries.length;i++){

            let propertyValues = Object.values(entries[i]);

            rats.push(Number(propertyValues[1].rating));
          }

          let total = 0;
          for (let i=0;i<rats.length;i++){
            total += rats[i];
          }
          let avg = total/rats.length;

            if(document.getElementById('avgrat') !== null){
          document.getElementById('avgrat').innerText = 'Average rating: ' + avg.toFixed(1);
          creativeJSONLD.aggregateRating.ratingValue = String(avg.toFixed(1));


           element = (<Helmet>
            {/* Schema.org tags */}
            <script type='application/ld+json'>
            {JSON.stringify(creativeJSONLD)}
            </script>
          </Helmet>);
          ReactDOM.render(

              element,
              document.getElementById('json')

          );
        }

        } else {
          document.getElementById('numof').innerText = 'Number of ratings: 0';
          document.getElementById('avgrat').innerText = 'Average rating: 0';

          creativeJSONLD.aggregateRating.ratingValue = '0';
          creativeJSONLD.aggregateRating.ratingCount = '0';


        }

      });

    }


  function json(url) {
        return fetch(url).then(res => res.json());
  }


  const testFire = (newRating) => {

  json(`https://api.ipify.org?format=json`).then(data => {


    BrewstrRef.once("value").then(function(snapshot){
          var loginInfo=snapshot.val();

          if((loginInfo !== null)&&(loginInfo !== undefined)){
          const entries = Object.entries(loginInfo);

          //document.getElementById('numof').innerText = 'ilość ocen: ' + entries.length;


          let ips = [];
          //let rats = [];

          for (let i=0;i<entries.length;i++){

            let propertyValues = Object.values(entries[i]);


            ips.push(propertyValues[1].ip);

          }

          if (ips.includes(data.ip)===true){
            document.getElementById('rated').style.display = 'block';
            document.getElementById('rated').innerText = 'It looks like you have already rated the article.';
            setTimeout(function(){
            if (document.getElementById('rated')!==null){
              document.getElementById('rated').style.display = 'none';
            }
          },1500)
          } else {
            BrewstrRef.push().set({rating:newRating,ip: data.ip});
            readRatings();
          }
        } else {
          BrewstrRef.push().set({rating:newRating,ip: data.ip});
          readRatings();
        }

        });
  });

  }






    useEffect(() => {
       matchAuthor();
       readRatings();
     });




















  const PostContent = contentComponent || Content


  return (

    <section className="section">
      {helmet}
      <div id="json"></div>
      <div  className="container content">

        {/* Treść */}
        <div className="columns box" style={{marginTop:'60px',marginLeft: '3px', backgroundColor:'#f5f5f5'}}>
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <sub style={{fontSize:'1.2em',fontWeight:'normal'}}>Reading time: <b>{time} min</b> </sub>
            <br /><br />
            <div>
            <figure style={{textAlign:'center'}}>
            {featuredimage ?
              featuredimage.childImageSharp ?
            <Img className="dynamic-img2 column" fluid={featuredimage.childImageSharp.fluid}
             alt={title}
             loading={'eager'}
             title={title}
             imgStyle={{position:'relative',objectFit:'contain',width:'100%',borderRadius: '15px',border:'10px solid black'}}
             />
             :
             <div><img alt={title} style={{borderRadius:'16px',border:'10px solid black'}} width="100%" height="auto" src={featuredimage}/></div>
             : null
           }
           <figcaption style={{fontStyle:'italic',margin:'10px',fontWeight:'400'}}>{title}</figcaption>
           </figure>
            </div>
            <br></br>
            <p style={{fontSize:'1.5em'}}><b>{description}</b></p>
            <br></br>
            {headings}
            <PostContent content={content} />
            <div style={{textAlign:'left'}}>
            <br />
            <br />



            <div className="single-comment box columns" style={{display:'flex',flexWrap:'wrap',alignItems:'center',justifyContent:'center'}}>
              <div className="comment-info column" style={{textAlign:'center',minWidth:'200px',maxWidth:'300px'}}>
                  <img id="author-avatar" style={{border:'5px solid #36b3d2',borderRadius:'5px',marginRight:'20px'}} width="200px" height="250px" src="/icons/icon-144x144.png" alt="avatar" />
              </div>
              <div className="comment-data column" style={{paddingLeft: '20px',textAlign:'left'}}>
                <div className="comment-author"><b><p style={{fontSize:'2em'}} id="author">{author}</p></b></div>
                <div className="comment-text"><p id="bio">...</p></div>
              </div>

            </div>



            </div>
            <br />
            <br />
            <p style={{textAlign:'center'}}>Rate article:</p>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <ReactStars
                 count={5}
                 onChange={testFire}
                 size={34}
                 activeColor="#36b3d2"
               />
            </div>

              <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
              <sub id='numof'></sub>&nbsp;&nbsp;&nbsp;
              <sub id='avgrat'></sub>
              </div>

            <p style={{display:'none',textAlign:'center'}} id='rated'></p>
            <br />

            <div style={{textAlign:'center'}}>
            <span  className="subtitle" style={{fontSize:'13px'}}> Number of paragraphs: <b>{paragraphs}</b> &bull; Number of sentences: <b>{sentences}</b> &bull; Number of words: <b>{words}</b></span>
            </div>
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <p className="title">Tags:</p>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `- tag`}>
                      <Link className="btn" to={`/tags/${kebabCase(tag)}/`} style={{padding:'10px',fontSize:'12px'}} >{tag}</Link>
                    </li>
                  ))}
                </ul>

              </div>
            ) : null}
          </div>


        </div>

        <br />



      </div>
    </section>
  )


}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  headings: PropTypes.object,
  id: PropTypes.string,
  paragraphs: PropTypes.number,
  sentences: PropTypes.number,
  words: PropTypes.number,
}

const BlogPost = ({ data, pageContext }) => {
  let { markdownRemark: post } = data
  let posts = data.allMarkdownRemark.edges
  const {next, prev} = pageContext

/*
  let autoSigned
  autoSigned = post.html.match(/<img[^>]*>/g)

  let newItems = []

if(autoSigned !== null){
  autoSigned.forEach((item, i) => {
  let z = item.slice(item.indexOf('title="'),-2).replace(/title="/g,'')
  item = '<figure>'+item+'<figcaption style="text-align:center;">'+z+'</figcaption></figure>';
  newItems.push(item);
  });


  var i = -1;

  post.html = post.html.replace(/<img[^>]*>/g, function() {
  i++;
  return newItems[i];
  })
} */

useEffect(() => {
  if(post.frontmatter.english){
   document.getElementById('lang-switch').href = post.frontmatter.english
 }
 });

  let filterPosts = []
  posts.forEach((item, i) => {
    if(item.node.frontmatter.title !== post.frontmatter.title) {
      filterPosts.push(item)
    }
  });

  const blogPostingSchemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'BlogPosting',
    url: 'https://e-multicontent.com'+ post.fields.slug,
    name: post.frontmatter.title.substring(0, 60),
    alternateName: post.frontmatter.title.substring(0, 60) || '',
    headline: post.frontmatter.description.substring(0, 120),
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://e-multicontent.com'+ post.fields.slug,
    },
    author: {
      '@type': 'Organization',
      name: 'Zespół e-multiconent',
      url: 'https://e-multicontent.com/about-us/'
    },
    image: {
      '@type': 'ImageObject',
      url: 'https://e-multicontent.com'+post.frontmatter.featuredimage.publicURL,
    },
    datePublished: post.frontmatter.date,
    dateModified: post.frontmatter.date,
    publisher: {
      '@type': 'Person',
      name: post.frontmatter.author,
      logo: {
        '@type': 'ImageObject',
        url: 'https://e-multicontent.com/icons/icon-512x512.png',
      },
    },
    description: post.frontmatter.description.substring(0, 145)+'...',
  }



  return (
    <Layout>
    <nav className="breadcrumbs-nav section">
        <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<Link className="inline-link" to="/blog/">Blog</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600'}}><b>{post.frontmatter.title}</b></span>
    </nav>
      <BlogPostTemplate
        content={post.html.replace(/href/g, "rel='noopener noreferrer' target='_blank' href")}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | e-multiconent">
            <title>{`${post.frontmatter.title.substring(0, 60)}`}</title>
            <meta name="description" content={`${post.frontmatter.description.substring(0, 145)+'...'}`}/>
            <meta name='image' content={'https://e-multicontent.com'+post.frontmatter.featuredimage.publicURL} />

            {/* Schema.org tags */}
            <script type='application/ld+json'>
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Blog",
                "item": "https://e-multicontent.com/blog/"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": post.frontmatter.title,
                "item": 'https://e-multicontent.com'+post.fields.slug
              }]
            })}
            </script>
            <script type='application/ld+json'>
              {JSON.stringify(blogPostingSchemaOrgJSONLD)}
            </script>

            {/* OpenGraph tags */}
            <meta property="og:title" content={post.frontmatter.title} />
            <meta property='og:type' content='article' />
            <meta property="og:description" content={post.frontmatter.description.substring(0, 145)+'...'} />
            <meta property="og:image" content={'https://e-multicontent.com'+post.frontmatter.featuredimage.publicURL}/>
            <meta property="og:url" content={'https://e-multicontent.com'+post.fields.slug}/>

            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="e-multicontent.com" />
            <meta property="twitter:url" content={'https://e-multicontent.com'+post.fields.slug} />
            <meta name="twitter:title" content={post.frontmatter.title} />
            <meta name="twitter:description" content={post.frontmatter.description.substring(0, 145)+'...'} />
            <meta name="twitter:image" content={'https://e-multicontent.com'+post.frontmatter.featuredimage.publicURL} />
            <link rel="alternate" href={post.frontmatter.english} hrefLang="pl-pl" />
            <link rel="canonical" href={"https://e-multicontent.com" + post.fields.slug}  />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        author={post.frontmatter.author}
        featuredimage={post.frontmatter.featuredimage}
        slug={post.fields.slug}
        id={post.id}
        time={post.timeToRead}
        paragraphs={post.wordCount.paragraphs}
        sentences={post.wordCount.sentences}
        words={post.wordCount.words}
        headings={<ToC headings={post.headings} />}
      />
      <div className="container" style={{padding:'5%',maxWidth:'90%'}}>
      <CommentsSection title={post.frontmatter.title} />
      </div>
      <div style={{width:'70%',marginLeft:'15%'}}>

      <br />
      <br />
      <div className="columns">
      {prev && prev.frontmatter.templateKey === 'blog-post' ? <Link className="column btn" style={{textAlign:'center'}} to={prev.fields.slug} ><span style={{color:'#333',fontSize:'13px'}}>Next post:</span><br /><br /> &#8678; {prev.frontmatter.title} <br /><br /> <span style={{color:'#333',fontSize:'12px'}}>Reading time: {prev.timeToRead} min</span> </Link>  : ' '}
      {next && next.frontmatter.templateKey === 'blog-post' ?  <Link className="column btn" style={{textAlign:'center'}} to={next.fields.slug}><span style={{color:'#333',fontSize:'13px'}}>Previous post:</span><br /><br />{next.frontmatter.title} &#8680;<br /><br /> <span style={{color:'#333',fontSize:'12px'}}>Reading time: {next.timeToRead} min</span></Link> : ' '}
      </div>


      <br></br>


      <br></br>
      <br></br>
      <div align="center">
      <img loading="lazy" src="../../img/blog-anime.gif" alt="blog animation - copywriting, translations, SEO, websites, e-commerce, marketplace" width="200px" />



      </div>
      <h4 style={{fontSize:'1.7em',fontWeight:'800'}}>Read <b style={{color:'#36b3d2'}}>more</b>:</h4>

        <BlogRoll3 posts={filterPosts.slice(0,4)} />
        <br></br>
        <h4><Link style={{width:'80%',marginLeft:'10%'}} to="/blog/" className="btn" >blog</Link></h4>
        <h4><Link style={{width:'80%',marginLeft:'10%'}} to="/" className="btn" >home</Link></h4>
        <h4><Link style={{width:'80%',marginLeft:'10%'}} to="/contact-us/" className="btn" >contact us</Link></h4>
      </div>
      <br></br>
      <br></br>
      <br></br>

    </Layout>

  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),location: PropTypes.shape({}).isRequired,
}



export default BlogPost



export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      headings {
        value,
        depth
      }
      html
      wordCount {
       paragraphs
       sentences
       words
      }
      timeToRead
      frontmatter {
        date(formatString: "DD. MM. YYYY")
        title
        description
        english
        author
        tags
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              srcWebp
              src
              srcSet
              srcSetWebp
            }
          }
          publicURL
        }

      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            industry
            description
            templateKey
            date(formatString: "DD. MM. YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                }
              }
              publicURL
            }
            backgroundimage {
              publicURL
            }
            tags
          }
        }
      }
    }
  }
`
